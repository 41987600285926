export const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

// Funcion para hacer scroll
export const scrollWithOffset = (e) => {
    const CoordinateY = e.getBoundingClientRect().top + window.pageYOffset;
    const offsetY = window.scrollY > CoordinateY ? -65 : 0;
    window.scrollTo({ top: CoordinateY + offsetY, behavior: 'smooth' });
}