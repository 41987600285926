import emailjs from 'emailjs-com';
const ServiceId = 'service_jhl5h4f';
const UserId = process.env.REACT_APP_EMIALJS_USERID;

export const RequestEmail = async (data) => {
  try {
    const templateIdToClient = 'template_2tm0v4r'
    // await emailjs.send(ServiceId, templateIdAdvise, data, UserId);
    await emailjs.send(ServiceId, templateIdToClient, data, UserId);
    return true
  } catch (error) {
    return false
  }
}
