import styled from '@emotion/styled';
// Colores
import {
  ColorMainBackground,
  ColorTextDark1,
} from '../../utilities/Pallete.const';
// Componentes Genericos
import MainWrapper from '../common/MainWrapper.styled';
import Card from '../common/Card.styled';

const WhyUsWrapper = styled(MainWrapper)`
  background-color: ${ColorMainBackground};
  flex-direction: column;
`;
const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 5px;
  @media only screen and (min-width: 768px){
    flex-direction: row;
    margin-top: 50px;
  }
`;
const CardItem = styled(Card)`
  margin: 20px 0;
  &:first-of-type{
    margin-top: 30px;
  }
  &:last-of-type{
    margin-bottom: 10px;
  }
  svg{
    max-height: 80px;
    margin-bottom: 20px;
  }
  @media only screen and (min-width: 768px){
    margin: 0 30px;
    &:first-of-type{
      margin: 0;
    }
    &:last-of-type{
      margin: 0;
    }
    &:nth-of-type(2n){
      margin-top: 70px
    }
    &:nth-of-type(2n+1){
      margin-bottom: 70px
    }
  }
  @media only screen and (min-width: 900px){
    margin: 0 5vw;
    &:nth-of-type(2n){
      margin-top: 100px
    }
    &:nth-of-type(2n+1){
      margin-bottom: 100px
    }
  }
  @media only screen and (min-width: 1024px){
    padding: 50px 35px;
    svg{
      margin-bottom: 60px;
    }
  }
`;
const CardItemText = styled.p`
  color: ${ColorTextDark1};
  font-weight: bold;
`;

export {
  WhyUsWrapper,
  CardsContainer,
  CardItem,
  CardItemText,
}