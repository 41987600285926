export const ColorMainBackground = 'rgb(255, 255, 255)';
export const ColorAuxiliarBackground = 'rgb(255, 230, 181)';

export const ColorPrimary = '#FF6633';
export const ColorPrimaryLight = '#FFA385';
export const ColorPrimaryDark = '#E03800';

export const ColorAccent = '#336699';
export const ColorAccentLight = '#578FC7';
export const ColorAccentDark = '#1F3D5C';

export const ColorAccent2 = 'rgb(214, 40, 40)';
export const ColorAccent2Dark = 'rgb(172, 32, 32)';

export const ColorTextLight1 = 'rgb(255, 255, 255)';
export const ColorTextDark1 = 'rgb(63, 63, 63)';
