import { useEffect, useRef } from "react";
// Styled componetes
import {
  NavMenuWrapper,
  NavItem
} from './Menu.styled'
// Utilidades
import { scrollWithOffset } from "../../utilities/utils";

const NavMenu = ({ menuIsActive, hamburgerIsActive, setMenuActive }) => {
  const prevScrollpos = useRef(window.pageYOffset);
  // Funcion para esconder el header al hacer scroll down

  useEffect(() => {
    const controlMenu = () => {
      const currentScrollPos = window.pageYOffset;
      if (hamburgerIsActive) {
        setMenuActive(prevScrollpos.current > currentScrollPos);
      }
      prevScrollpos.current = currentScrollPos;
    }
    window.addEventListener('scroll', controlMenu);
    return () => {
      window.removeEventListener('scroll', controlMenu);
    }
  }, [hamburgerIsActive, setMenuActive])

  return (
    <NavMenuWrapper isActive={menuIsActive}>
      <NavItem scroll={e => scrollWithOffset(e)} to="#home">¿Que hacemos?</NavItem>
      <NavItem scroll={e => scrollWithOffset(e)} to="#advantages">Nuestros Servicios</NavItem>
      <NavItem scroll={e => scrollWithOffset(e)} to="#whatNeed">Llenado de reseñas en Google</NavItem>
      <NavItem scroll={e => scrollWithOffset(e)} to="#whyUs">¿Por qué nosotros?</NavItem>
      <NavItem scroll={e => scrollWithOffset(e)} to="#contactForm">Contáctanos</NavItem>
    </NavMenuWrapper>
  );
}

export default NavMenu