import styled from "@emotion/styled";
// Colores
import {
  ColorTextDark1
} from '../../utilities/Pallete.const';

const SubtitleSection = styled.h2`
  text-align: center;
  margin: 0;
  color: ${ColorTextDark1};
`
export default SubtitleSection;