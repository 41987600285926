import React from "react";
import {
  HeroWrapper,
  HeroTextContainer,
  HeroMainTitle,
  HeroTextParagraph,
  HeroIllustrationContainer,
} from './Hero.styled';
// Componentes Genericos
import ButtonAction from "./../common/ButtonAction.styled";
// Assets
import { ReactComponent as HeroImage } from "./../../assets/illustrations/woman_idea.svg";
// Utilidades
import { scrollWithOffset } from "../../utilities/utils";

const Hero = () => {
  return (
    <HeroWrapper id="home">
      <HeroTextContainer>
        <HeroMainTitle>¿Que hacemos?</HeroMainTitle>
        <HeroIllustrationContainer className="visible-mobile">
          <HeroImage />
        </HeroIllustrationContainer>
        <HeroTextParagraph>
          Nuestro equipo de copywriters está altamente capacitado para hacer que su marca brille con palabras poderosas. Con años de experiencia en la industria, entendemos lo que funciona y lo que no.
          <br />
          Nos enfocamos en transmitir su mensaje de manera clara y memorable, para atraer y retener a su audiencia. Deje que nos encarguemos de crear un contenido de alta calidad para su sitio web, anuncios publicitarios y redes sociales.
          <br />
          <br />
          <b>
            ¡Trabajemos juntos para hacer que su marca tenga una voz fuerte y distintiva!
          </b>
        </HeroTextParagraph>
        <ButtonAction scroll={e => scrollWithOffset(e)} to="#contactForm">
          Comencemos!
        </ButtonAction>
      </HeroTextContainer>
      <HeroIllustrationContainer className="visible-desktop-flex">
        <HeroImage />
      </HeroIllustrationContainer>
    </HeroWrapper>
  );
}
export default Hero