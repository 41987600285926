import styled from "@emotion/styled"

// Colores
import {
  ColorAuxiliarBackground,
  ColorPrimaryDark,
  ColorTextDark1,
} from '../../utilities/Pallete.const';

const Card = styled.div`
  background-color: ${ColorAuxiliarBackground};
  border: ${ColorPrimaryDark} 1px solid;
  border-radius: 30px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 25px 40px 25px;
  text-align: center;
  font-family: 'Overlock', sans-serif;
`;

const SmallCard = styled(Card)`
  padding: 15px;
  border-radius: 15px;
  color: ${ColorTextDark1};
  font-size: 18px;
  font-weight: bold;
`;

export default Card;
export { SmallCard }