import { useState } from "react";
// Librerias
import { BrowserRouter as Router } from "react-router-dom";
// Componentes
import Landing from "./pages/Landing"
import NavBar from "./components/NavBar/Navbar.component";
import NavMenu from "./components/Menu/Menu.component";
import Footer from "./components/Footer/Footer.component";

function App() {
  // Funcion para activar menu
  const [menuActive, setMenuActive] = useState(false);
  const [hamburguerActive, setHamburguerActive] = useState(false);
  const ActivateMenu = e => {
    setHamburguerActive(!hamburguerActive);
    setMenuActive(!menuActive)
  }

  return (
    <Router>
      <NavBar isMenuButtonActive={hamburguerActive} clickMenuButton={ActivateMenu}/>
      <NavMenu menuIsActive={menuActive} hamburgerIsActive={hamburguerActive} setMenuActive={setMenuActive}/>
      <Landing/>
      <Footer/>
    </Router>
  );
}

export default App;
