import styled from "@emotion/styled";
import {
  ColorAccentDark
} from '../../utilities/Pallete.const';

const NavbarWrapper = styled.nav`
  background-color: ${ColorAccentDark};
  width: 100%;
  height: 65px;
  position: fixed;
  padding: 17px 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  z-index: 10;
  transform: translateY(-100%);
  transition: all ease-in-out 0.2s;
  ${props => props.visible ? `
    transform: translateY(0);
    ` : ''}
  @media only screen and (min-width: 500px){
    padding: 17px 35px;
  }
  @media only screen and (min-width: 768px){
    padding: 17px 5vw;
  }
  @media only screen and (min-width: 1024px){
    padding: 17px 8vw;
  }
`;

const LogoWrapper = styled.div`
  width: 100%;
  svg{
    height: 100%;
  }
`
export {
  NavbarWrapper,
  LogoWrapper
}