import styled from "@emotion/styled"

// Colores
import {
  ColorMainBackground,
  ColorTextDark1,
} from '../../utilities/Pallete.const';

// Componentes Genericos
import MainWrapper from '../common/MainWrapper.styled';
import Card from "../common/Card.styled";

const AdvantagesWrapper = styled(MainWrapper)`
  background-color: ${ColorMainBackground};
  flex-direction: column;
`;
const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 25px;
  @media only screen and (min-width: 768px){
    flex-direction: row;
    margin-top: 50px;
  }
  @media only screen and (min-width: 1024px){
    margin-top: 80px;
  }
`;
const CardItem = styled(Card)`
  position: relative;
  z-index: 1;
  margin: 40px 0;
  &:first-of-type{
    margin-top: 70px;
  }
  &:last-of-type{
    margin-bottom: 30px;
  }
  @media only screen and (min-width: 500px){
    padding-top: 100px;
    margin: 45px 0;
  }
  @media only screen and (min-width: 768px){
    margin: 0 30px;
    &:first-of-type{
      margin: 0;
    }
    &:last-of-type{
      margin: 0;
    }
    &:nth-child(3){
      svg{
        margin-top: 10px;
      }
    }
  }
  @media only screen and (min-width: 900px){
    margin: 0 5vw;
    padding-top: 150px;
  }
  @media only screen and (min-width: 1024px){
    padding-top: 180px;
    &:nth-child(3){
      svg{
        margin-top: 40px;
      }
    }
  }

`;
const ImageContainer = styled.div`
  position: absolute;
  top: -60px;
  svg{
    max-height: 120px;
    width: 100%;
  }
  svg:nth-child(2){
    margin-top: 40px;
  }
  @media only screen and (min-width: 500px){
    svg {
      max-height: 150px;
    }
  }
  @media only screen and (min-width: 900px){
    svg {
      max-height: 180px;
    }
  } 
  @media only screen and (min-width: 1024px){
    svg {
      max-height: 220px;
    }
  } 
`
const CardTitle = styled.h3`
  color: ${ColorTextDark1};
  margin-bottom: 15px;
  @media only screen and (min-width: 768px){
    margin-bottom: 25px;
  }
  @media only screen and (min-width: 900px){
    margin-bottom: 35px;
  }
`
const CardText = styled.p`
  color: ${ColorTextDark1};
  font-weight: bold;
`;

export {
  AdvantagesWrapper,
  CardsContainer,
  ImageContainer,
  CardItem,
  CardTitle,
  CardText
}