import React from 'react';
// Componentes Genericos
import SubtitleSection from '../common/SubtitleSection.styled';
// Styled components
import {
  WhyUsWrapper,
  CardsContainer,
  CardItem,
  CardItemText,
} from "./Whyus.styled";
// Assets
import { ReactComponent as FlagImage } from "./../../assets/illustrations/flag.svg";
import { ReactComponent as LaptopImage } from "./../../assets/illustrations/laptop.svg";
import { ReactComponent as GradeImage } from "./../../assets/illustrations/grade.svg";

const ItemsList = [
  {
    image: <FlagImage />,
    content: <>
      Entregas en lapsos de tiempo rapidos y cumplimiento de los objetivos establecidos.
    </>
  },
  {
    image: <GradeImage />,
    content: <>
      Comunicación efectiva y atención al cliente.
    </>
  },
  {
    image: <LaptopImage />,
    content: <>
      Habilidad para crear mensajes claros y efectivos.
    </>
  },
]

const WhyUs = () => {
  return (
    <WhyUsWrapper id="whyUs">
      <SubtitleSection>¿Porque Nosotros?</SubtitleSection>
      <CardsContainer>
        {
          ItemsList.map((e, i) => (
            <CardItem key={i}>
              {e.image}
              <CardItemText>
                {e.content}
              </CardItemText>
            </CardItem>
          ))
        }
      </CardsContainer>
    </WhyUsWrapper>
  );
}

export default WhyUs
