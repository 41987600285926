import styled from '@emotion/styled';
// Colores
import {
  ColorMainBackground,
  ColorPrimaryDark,
} from '../../utilities/Pallete.const';

const FooterWrapper = styled.footer`
  background-color: ${ColorPrimaryDark};
  padding: 10px 25px;
  color: ${ColorMainBackground};
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 768px){
    padding: 12px 50px;
    flex-direction: row;
  }
`;
const FooterContent = styled.p`
  flex: 1;
  text-align: center;
  font-size: 0.85em;
  &:first-of-type{
    margin-bottom: 5px;
  }
  @media only screen and (min-width: 768px){
    margin: 0;
    font-size: 0.9em;
  }
  @media only screen and (min-width: 1024px){
    font-size: 1em;
  }
`;

export {
  FooterWrapper,
  FooterContent
}