import React from "react";
// Componentes
import Hero from "../components/Hero/Hero.component";
import Advantages from "../components/Advantages/Advantages.component";
import WhatNeed from "../components/WhatNeed/WhatNeed.component";
import WhyUs from "../components/WhyUs/Whyus.component";
import ContactForm from "../components/ContactForm/ContactForm.component";
// Estilos
import { LandingWrapper } from "./Landing.styled";

const Landing = () => {
  return (
    <LandingWrapper>
      <Hero/>
      <Advantages/>
      <WhatNeed/>
      <WhyUs/>
      <ContactForm/>
    </LandingWrapper>
  );
}

export default Landing