import styled from "@emotion/styled";
import { HashLink } from "react-router-hash-link";
import { ColorMainBackground } from "../../utilities/Pallete.const";

const ColorAccentDarkFade1 = 'rgba(0, 47, 73, 0.85)';
const ColorAccentDarkFade2 = 'rgba(0, 47, 73, 0.9)';

const NavMenuWrapper = styled.ul`
  width: 100%;
  background-color: ${ColorAccentDarkFade1};
  position: fixed;
  margin-top: 65px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  padding: 20px 25px 30px 25px;
  box-sizing: border-box;
  transition: all 0.25s ease-in-out;
  transform: translateY(calc(-100% - 80px));
  ${props => props.isActive ? `
      transform: translateY(0);
  ` : ''};
  @media only screen and (min-width: 768px){
    width: 40vw;
    right: 0;
  }
  @media only screen and (min-width: 1024px){
    width: 35vw;
  }
`;

const NavItem = styled(HashLink)`
  color: ${ColorMainBackground};
  font-size: 2rem;
  padding: 15px 0;
  text-align: center;
  &:hover{
    background-color: ${ColorAccentDarkFade2};
  }
  @media only screen and (min-width: 768px){
    font-size: 1.6rem;
  }
`;

export {
  NavMenuWrapper,
  NavItem
}