import {
  FooterWrapper,
  FooterContent
} from './Footer.styled'

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        Copywriter Profesional &copy;
      </FooterContent>
      <FooterContent>
        Todos los derechos reservados {new Date().getFullYear()}
      </FooterContent>
    </FooterWrapper>
  );
}

export default Footer;