import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';


const config = {
  "projectId": "creandoandowebsite",
  "appId": "1:1037095491067:web:626135cc2228ddc48e275e",
  "storageBucket": "creandoandowebsite.appspot.com",
  "locationId": "us-central",
  "apiKey": "AIzaSyAVtHCViLzmAkeIMP39xWsLKDPrOfzDG18",
  "authDomain": "creandoandowebsite.firebaseapp.com",
  "messagingSenderId": "1037095491067",
  "measurementId": "G-2DXFEDVPD7"
};

export const app = firebase.initializeApp(config);
