import styled from "@emotion/styled";
// Colores
import {
  ColorAuxiliarBackground,
  ColorTextDark1
} from '../../utilities/Pallete.const';
// Componentes Genericos
import MainWrapper from '../common/MainWrapper.styled';

const WhatNeedWrapper = styled(MainWrapper)`
  background-color: ${ColorAuxiliarBackground};
  flex-direction: column;
  @media only screen and (min-width: 768px){
    flex-direction: row;
  }
`;
const WhatNeedTextContainer = styled.div`
  flex: 1;
  @media only screen and (min-width: 768px){
    margin-left: 50px;
  }
  @media only screen and (min-width: 900px){
    margin-left: 70px;
  }
  @media only screen and (min-width: 1024px){
    margin-left: 5vw;
  }
`;
const WhatNeedTextParagraph = styled.p`
  margin: 20px 0;
  color: ${ColorTextDark1};
  @media only screen and (min-width: 900px){
    margin: 40px 0;
  }
  @media only screen and (min-width: 1366px){
    margin: 10vh 0;
  }
`;
const WhatNeedIllustrationContainer = styled.div` 
  margin: 25px 0;
  @media only screen and (min-width: 500px){
    padding: 8%;
    svg {
      width: 100%;
    }
  }
  @media only screen and (min-width: 768px){
    flex: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;


export {
  WhatNeedWrapper,
  WhatNeedTextContainer,
  WhatNeedTextParagraph,
  WhatNeedIllustrationContainer
}
