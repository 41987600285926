import styled from "@emotion/styled";
// Colores
import {
  ColorAccent2,
  ColorMainBackground,
  ColorPrimary,
  ColorPrimaryDark,
  ColorPrimaryLight,
  ColorTextDark1,
} from '../../utilities/Pallete.const';
// Componentes Genericos
import MainWrapper from "../common/MainWrapper.styled";
import Card, { SmallCard } from "../common/Card.styled";

const ContactFromWrapper = styled(MainWrapper)`
  flex-direction: column;
  padding-top: 10px;
  @media only screen and (min-width: 768px){
    flex-direction: row;
    justify-content: space-between;
  }
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 768px){
    width: calc(45vw - 30px);
  }
  @media only screen and (min-width: 1024px){
    width: calc(42vw - 50px);
  }
`;
const InfoParagraph = styled.p`
  color: ${ColorTextDark1};
  font-weight: bold;
  margin: 25px 0;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media only screen and (min-width: 1024px){
    width: 80%;
  }
`;
const OptionButton = styled(SmallCard)`
  width: 120px;
  height: 120px;
  flex: none;
  justify-content: space-evenly;
  border-color: ${ColorPrimaryLight};
  cursor: pointer;
  ${props => props.active ? `
    border-color: ${ColorPrimaryDark};
    cursor: cancel;  
  `: ''};
  i{
    font-size: 50px;
    color: ${ColorPrimaryLight};
    ${props => props.active ? `
      color: ${ColorPrimaryDark};
    `: ''};
  }
`;
const EmailContainer = styled(SmallCard)`
  flex-direction: row;
  flex: none;
  font-size: 0.9em;
  i{
    color: ${ColorPrimaryDark};
    margin-right: 10px;
  }
  position: relative;
  &:hover span{
    opacity: 1;
  }
  @media only screen and (min-width: 500px){
    font-size: 16px;
  }
  @media only screen and (min-width: 1024px){
    width: 80%;
  }
`;
const EmailCopyButton = styled.span`
  cursor: pointer;
  position: absolute;
  right: 10px;
  background-color: ${ColorPrimaryLight};
  padding: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  font-size: 0.85em; 
  opacity: 0;
  transition: all ease-in-out 0.2s;
  i{
    color: ${ColorTextDark1};
    margin: 0;
  }
`;
const FormWrapper = styled(Card)`
  margin-top: 50px;
  padding: 50px 25px;
  flex: none;
  @media only screen and (min-width: 500px){
    padding: 50px 10vw;
  }
  @media only screen and (min-width: 768px){
    width: calc(45vw - 30px);
    margin: 0;
    padding: 50px 5vw;
  }
  @media only screen and (min-width: 1024px){
    width: calc(42vw - 50px);
  }

`;
const FormContainer = styled.div`
  flex-direction: column;
  width: 100%;
  display: none;
  ${props => props.visible
    ? `
    display: flex;
  `
    : ''};
`;
const FormGroup = styled.div`
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${ColorTextDark1};
`;
const AlertLabel = styled.div`
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Overlock', sans-serif;
  font-size: 16px;
  padding: 8px 30px;
  text-align: center;
  font-weight: bold;
  background-color: ${ColorAccent2};
  color: ${ColorMainBackground};
  transition: all ease-in-out 0.2s;
  transform: translateY(0);
  opacity: 0;
  height: 0;
  ${props => props.active 
  ? `
    opacity: 1;
    transform: translateY(0);
    height: auto;
  ` : ''}
`;
const InputLabel = styled.label`
  font-family: 'Overlock', sans-serif;
  font-size: 15px;
  text-align: left;
  font-weight: bold;
`;
const InputForm = styled.input`
  background-color: ${ColorMainBackground};
  margin: 10px 0;
  padding: 10px;
  font-family: 'Overlock', sans-serif;
  font-size: 16px;
`;
const TextAreaForm = styled.textarea`
  background-color: ${ColorMainBackground};
  margin: 10px 0;
  padding: 10px;
  font-family: 'Overlock', sans-serif;
  font-size: 16px;
  resize: vertical;
`
const SelectForm = styled.select`
  background-color: ${ColorMainBackground};
  margin: 10px 0;
  padding: 10px;
  font-family: 'Overlock', sans-serif;
  font-size: 16px;
`;
const AddFileButton = styled.p`
  background-color: ${ColorPrimary};
  color: ${ColorMainBackground};
  padding: 8px 30px;
  margin: 15px 0;
  display: block;
  font-size: 16px;
  transition: 0.25s all ease-in-out;
  text-align: center;
  cursor: pointer;
  position: relative;
  &:hover{
    box-shadow: 0 0 10px ${ColorPrimary};
    transform: scale(1.01);
  }
  @media only screen and (min-width: 500px){
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (min-width: 1024px){
    padding: 12px 20px;
  }
`;
const AddFileName = styled.span`
  bottom: -25px;
  left: 0;
  position: absolute; 
  width: 100%;
  font-family: 'Overlock', sans-serif;
  color: ${ColorTextDark1};
  font-size: 13px;
  text-align: left;
  transition: all ease-in-out 0.2s;
  opacity: 0;
  transform: translateY(-100%);
  ${props => props.active
  ? `
    opacity: 1;
    transform: translateY(0);
  `
  : ''}
`;

export {
  ContactFromWrapper,
  InfoWrapper,
  InfoParagraph,
  EmailContainer,
  EmailCopyButton,
  ButtonsContainer,
  OptionButton,
  FormWrapper,
  FormContainer,
  FormGroup,
  InputLabel,
  InputForm,
  TextAreaForm,
  SelectForm,
  AddFileButton,
  AddFileName,
  AlertLabel
}