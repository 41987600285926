import React from 'react';
// Componentes Genericos
import SubtitleSection from '../common/SubtitleSection.styled';
import ButtonAction from "./../common/ButtonAction.styled";
// Styled components
import {
  WhatNeedWrapper,
  WhatNeedTextContainer,
  WhatNeedTextParagraph,
  WhatNeedIllustrationContainer
} from "./WhatNeed.styled";
// Assets
import { ReactComponent as MenComputerImage } from "./../../assets/illustrations/men_computer.svg";
// Utilidades
import { scrollWithOffset } from "../../utilities/utils";

const WhatNeed = () => {
  return (
    <WhatNeedWrapper id="whatNeed">
      <WhatNeedIllustrationContainer>
        <MenComputerImage />
      </WhatNeedIllustrationContainer>
      <WhatNeedTextContainer>
        <SubtitleSection>
          Llenado de reseñas en Google
        </SubtitleSection>
        <WhatNeedTextParagraph>
        Mejora tu reputación en línea con <b>reseñas positivas en Google.</b> Nuestro servicio de llenado de reseñas te ayuda a atraer más clientes. <b>Expertos en llenado de reseñas positivas.</b> ¡Aprovecha al máximo la fuerza de las opiniones en línea! Contáctanos hoy.
        </WhatNeedTextParagraph>
        <ButtonAction scroll={e => scrollWithOffset(e)} to="#contactForm">
          Ir al formulario
        </ButtonAction>
      </WhatNeedTextContainer>
    </WhatNeedWrapper>
  );
}

export default WhatNeed;