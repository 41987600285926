import styled from '@emotion/styled';
// Paleta Colores
import {
  ColorTextLight1,
} from '../../utilities/Pallete.const';

const MenuBurgerWrapper = styled.div`
  width: 45px;
  cursor: pointer;
`;
const MenuBurgerContainer = styled.div`
  width: 100%;
  height: 3px;
  background-color: ${ColorTextLight1};
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
  position: relative;
  margin-top: 15px;
  ${props => props.active ? `
      transform: translateX(-100%);
      background: transparent;
      ` : ''};
  &::before,
  &::after{
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: ${ColorTextLight1};
      border-radius: 2px;
      transition: all 0.3s ease-in-out;
  }
  &::before{
      transform: translateY(12px);
      ${props => props.active ? `
          transform: rotate(45deg) translate(35px, -35px);
      ` : ''};
  }
  &::after{
      transform: translateY(-12px);
      ${props => props.active ? `
          transform: rotate(-45deg) translate(35px, 35px);
      ` : ''};
  }
`;

export {
  MenuBurgerWrapper,
  MenuBurgerContainer
};