import React, { useRef, useState } from 'react';
// Librerias
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// Esilos
import {
  ContactFromWrapper,
  InfoWrapper,
  InfoParagraph,
  EmailContainer,
  EmailCopyButton,
  FormWrapper,
  FormGroup,
  InputLabel,
  InputForm,
  TextAreaForm,
  AlertLabel
} from './ContactForm.styled'
// Componentes Genericos
import SubtitleSection from '../common/SubtitleSection.styled';
import ButtonAction from '../common/ButtonAction.styled';
import { Loader } from '../common/Loader.styled';
// Controlador
import { sendEmail, uploadData } from '../../controller/contactForm.controller';
// Utilidades
import { kEmailContactStr } from '../../utilities/Strings.const';
import { validateEmail } from '../../utilities/utils';
import { ColorPrimary } from '../../utilities/Pallete.const';

const ContactForm = () => {
  const MySwal = withReactContent(Swal);

  const [contentCopyEmail, setCcontentCopyEmail] = useState(<i className="fas fa-copy"></i>)

  // Funcion para copiar al clipboard el email
  const CopyEmail = e => {
    navigator.clipboard.writeText(kEmailContactStr);
    setCcontentCopyEmail(<i className="fas fa-check"></i>);
  }

  // Objeto para guardar informacion del formulario
  const [infoForm, setInfoForm] = useState({
    inName: '',
    inEmail: '',
    inCommentQuestions: '',
  });

  const handleChangeForm = e => {
    setInfoForm({
      ...infoForm,
      [e.target.name]: e.target.value
    });
  }

  // Metodo para validar formulario
  const [alertMessage, setAlertMessage] = useState('')
  const refValues = {
    name: useRef(),
    email: useRef(),
    commentQuestions: useRef(),
  }
  const ValidateForm = () => {
    setAlertMessage('');
    // Verificar campos obligatorios
    if (infoForm.inName === '') {
      refValues.name.current.focus();
      setAlertMessage('No olvides tu nombre');
      return false;
    }
    if (infoForm.inEmail === '') {
      refValues.email.current.focus();
      setAlertMessage('No olvides tu email');
      return false;
    }
    if (!validateEmail(infoForm.inEmail)) {
      refValues.email.current.focus();
      setAlertMessage('El email no es valido');
      return false;
    }
    if (infoForm.inCommentQuestions === '') {
      refValues.commentQuestions.current.focus();
      setAlertMessage('No olvides tu comentario');
      return false;
    }
    // Presupesto de preguntas visible
    return true;
  }

  // Metodo para enviar el formulario
  const [loaderSend, setLoaderSend] = useState(false);
  const sendForm = async () => {
    setLoaderSend(true);
    let request;
    if (ValidateForm()) {
      // Obtener identificador unico
      const today = new Date();
      const keyName = `${today.getFullYear()}${today.getMonth()}${today.getDay()}_${today.getHours()}${today.getMinutes()}${today.getMilliseconds()}`;
      
      const emailRequest = sendEmail(infoForm.inName, infoForm.inEmail, infoForm.inCommentQuestions, 'question');
      if (emailRequest)
        request = await uploadData(infoForm.inEmail, keyName, infoForm);
      else
        request = false;
    }
    // Mostrar alerta
    if (request) {
      // Alerta de exito
      MySwal.fire({
        icon: "success",
        title: <p>Tu mensaje ha sido enviado</p>,
        confirmButtonColor: ColorPrimary,
        confirmButtonText: <p style={{ fontSize: "1.2em" }}>Entendido</p>
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      })
    } else {
      // Alerta de error
      MySwal.fire({
        icon: "warning",
        title: <p>Algo salio mal en el proceso :(</p>,
        confirmButtonColor: ColorPrimary,
        confirmButtonText: <p style={{ fontSize: "1.2em" }}>Cargar de nuevo</p>
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      })
    }
    setLoaderSend(false);
  }


  return (
    <ContactFromWrapper id="contactForm">
      <InfoWrapper>
        <SubtitleSection>
          Ponte en Contacto
        </SubtitleSection>
        <InfoParagraph>
          Puedes escribirnos directamente
        </InfoParagraph>
        <EmailContainer>
          <i className="fas fa-envelope"></i>
          {kEmailContactStr}
          <EmailCopyButton onClick={CopyEmail}>
            {contentCopyEmail}
          </EmailCopyButton>
        </EmailContainer>
        <InfoParagraph>
          O puedes llenar el siguiente formulario:
        </InfoParagraph>
      </InfoWrapper>
      <FormWrapper>
        <AlertLabel active={alertMessage !== ''}>
          {alertMessage}
        </AlertLabel>
        <FormGroup>
          <InputLabel>
            Contacto (Tu nombre o el de tu empresa)*
          </InputLabel>
          <InputForm
            type="text"
            placeholder="Juan Hernandez"
            name="inName"
            ref={refValues.name}
            onChange={handleChangeForm}
          />
        </FormGroup>
        <FormGroup>
          <InputLabel>
            Email*
          </InputLabel>
          <InputForm
            type="email"
            name="inEmail"
            placeholder="juanhernandez@mail.com"
            ref={refValues.email}
            onChange={handleChangeForm}
          />
        </FormGroup>
        <FormGroup>
          <InputLabel>
            ¿En que podemos ayudarte? *
          </InputLabel>
          <TextAreaForm
            rows="10"
            name="inCommentQuestions"
            placeholder="Me preguntaba si pueden hacer que..."
            ref={refValues.commentQuestions}
            onChange={handleChangeForm}
          />
        </FormGroup>
        <ButtonAction
          margintop="30px"
          onClick={sendForm}
          disabled={loaderSend}
        >
          {loaderSend
            ? <Loader className="loader-sm light" />
            : 'Enviar'}
        </ButtonAction>
      </FormWrapper>
    </ContactFromWrapper>
  );
}

export default ContactForm;