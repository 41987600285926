// Librerias de conexion con firebase
import { app } from '../server/firebase.server';
import { RequestEmail } from '../server/email.server';
const db = app.firestore();
const storage = app.storage();

export const sendFile = async (kEmailUser, kKeyId, kData, file) => {
  try {
    const fileName = `${kKeyId}.stl`;
    const storageRef = storage.ref();
    const fileRef = storageRef.child(fileName);
    await fileRef.put(file);
    // Guardar informacion del archivo en Firestore
    // kData['url'] = await fileRef.getDownloadURL();
    // Subir informacion del archivo a firebase
    return uploadData(kEmailUser, kKeyId, kData);
  } catch (error) {
    return false;
  }
}

export const uploadData = async (kCollectionName, kDocName, kData) => {
  try {
    await db.collection(kCollectionName).doc(kDocName).set({ kData });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const sendEmail = async (kClientName, kClientEmail, kMessage) => {
  const SenData = {
    name: kClientName,
    email_to: kClientEmail,
    message: kMessage
  }
  return RequestEmail(SenData);
}
