import styled from '@emotion/styled';
import { HashLink } from "react-router-hash-link";
// Paleta Colores
import {
  ColorAccentLight,
  ColorMainBackground,
} from '../../utilities/Pallete.const';

const ButtonAction = styled(HashLink)`
  background-color: ${ColorAccentLight};
  color: ${ColorMainBackground};
  border-radius: 25px;
  padding: 8px 30px;
  margin: ${props=>props.marginTop || "50px"} auto 20px auto;
  width: 80%;
  display: block;
  transition: 0.25s all ease-in-out;
  text-align: center;
  cursor: pointer;
  &:hover{
    box-shadow: 0 0 10px ${ColorAccentLight};
    transform: scale(1.01);
  }
  @media only screen and (min-width: 500px){
    width: 70%;
  }
  @media only screen and (min-width: 1024px){
    padding: 12px 20px;
    width: 60%;
  }
`;

export default ButtonAction;