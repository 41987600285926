import { useState, useEffect } from "react";
// Assets
import { ReactComponent as IsoLogo } from "./../../assets/IsoLogoWhite.svg";
// Esilos
import {
  MenuBurgerWrapper,
  MenuBurgerContainer
} from "../common/MenuBurger.styled";
import {
  NavbarWrapper,
  LogoWrapper
} from "./Navbar.styled";

const NavBar = ({ isMenuButtonActive, clickMenuButton }) => {
  
  // Funcion para esconder el header al hacer scroll down
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    let prevScrollpos = window.pageYOffset;
    const controlHeader = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollpos > currentScrollPos);
      prevScrollpos = currentScrollPos;
    }
    window.addEventListener('scroll', controlHeader);
    return () => {
      window.removeEventListener('scroll', controlHeader);
    }
  })

  return (
    <NavbarWrapper visible={visible}>
      <LogoWrapper>
        <IsoLogo />
      </LogoWrapper>
      <MenuBurgerWrapper onClick={clickMenuButton}>
        <MenuBurgerContainer active={isMenuButtonActive} />
      </MenuBurgerWrapper>
    </NavbarWrapper>
  )
}

export default NavBar