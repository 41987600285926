import styled from "@emotion/styled";
import { ColorMainBackground, ColorTextDark1 } from "../../utilities/Pallete.const";

const Loader = styled.div`
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  border: 12px solid #cecece; /* Light grey */
  border-top: 12px solid rgb(255, 111, 0);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  animation: spin 2s linear infinite;
  &.loader-sm{
    width: 20px;
    height: 20px;    
    border-width: 3px;
  }
  &.dark{
    border-top-color:${ColorTextDark1};
  }
  &.light{
    border-top-color:${ColorMainBackground};
}
`;

export { Loader };