import styled from '@emotion/styled';
// Colores
import {
  ColorPrimaryLight,
  ColorTextDark1
} from '../../utilities/Pallete.const';
// Componentes Genericos
import MainWrapper from '../common/MainWrapper.styled';

const HeroWrapper = styled(MainWrapper)`
  background-color: ${ColorPrimaryLight};
  flex-direction: row;
`;
const HeroTextContainer = styled.div`
  flex: 1;
  @media only screen and (min-width: 900px){
    margin-right: 1.5vw;
  }
  @media only screen and (min-width: 1024px){
    margin-right: 5vw;
  }
`;
const HeroMainTitle = styled.h1`
  margin: 20px 0;
  color: ${ColorTextDark1};
  @media only screen and (min-width: 900px){
    margin: 40px 0;
  }
  @media only screen and (min-width: 1366px){
    margin: 4vh 0;
    font-size: 4.5rem;
  }
`;
const HeroTextParagraph = styled.p`
  margin: 20px 0;
  color: ${ColorTextDark1};
  @media only screen and (min-width: 900px){
    margin: 40px 0;
  }
  @media only screen and (min-width: 1366px){
    margin: 10vh 0;
  }
`;

const HeroIllustrationContainer = styled.div` 
  @media only screen and (min-width: 500px){
    padding: 8%;
    svg {
      width: 100%;
    }
  }
  @media only screen and (min-width: 768px){
    flex: 1;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (min-width: 900px){
    margin-left: 1.5vw;
  }
  @media only screen and (min-width: 1024px){
    margin-left: 5vw;
    padding: 0;
  }
`;

export {
  HeroWrapper,
  HeroTextContainer,
  HeroMainTitle,
  HeroTextParagraph,
  HeroIllustrationContainer,
}