// Componentes Genericos
import SubtitleSection from '../common/SubtitleSection.styled';
// Styled components
import {
  AdvantagesWrapper,
  CardsContainer,
  ImageContainer,
  CardItem,
  CardTitle,
  CardText
} from "./Advantages.styled";
// Assets
import { ReactComponent as Ilus1 } from "./../../assets/illustrations/Ilu1.svg";
import { ReactComponent as Ilus2 } from "./../../assets/illustrations/Ilu2.svg";
import { ReactComponent as Ilus3 } from "./../../assets/illustrations/Ilu3.svg";

const FeaturesList = [
  {
    title: 'Copywriting',
    image: <Ilus2 />,
    content: <>
      Nuestro servicio de copywriting te ayuda a destacar en un mercado saturado y a comunicar tu mensaje de marca de manera clara y atractiva. Con nuestro equipo de expertos en redacción publicitaria, aseguramos un contenido profesional y efectivo para tu sitio web, anuncios y campañas de redes sociales.
    </>,
  },
  {
    title: 'Artículos',
    image: <Ilus3 />,
    content: <>
    Fortalece tu presencia en línea con nuestro servicio de redacción de artículos. Con expertos en redacción y SEO, ofrecemos contenido informativo y relevante para mejorar tu posicionamiento en búsqueda y construir una audiencia leal. Ofrecemos contenido para blog, sitio web o revista en línea. ¡Contáctanos hoy para empezar!
    </>
  },
  {
    title: 'Traducción',
    image: <Ilus1 />,
    content: <>
    Ofrecemos traducciones precisas y confiables en una amplia gama de idiomas para asegurar que tu mensaje llegue sin barreras lingüísticas. Ofrecemos traducción para sitios web, documentos y material publicitario. Nuestro equipo de traductores profesionales te ayuda a expandir y fortalecer tu presencia en el mercado global.
    </>
  }
]

const Advantages = () => {
  return (
    <AdvantagesWrapper id="advantages">
      <SubtitleSection>
        Nuestros Servicios
      </SubtitleSection>
      <CardsContainer>
        {
          FeaturesList.map((e, i) => (
            <CardItem key={i}>
              <ImageContainer>
                {e.image}
              </ImageContainer>
              <CardTitle>
                {e.title}
              </CardTitle>
              <CardText>
                {e.content}
              </CardText>
            </CardItem>
          ))
        }
      </CardsContainer>
    </AdvantagesWrapper>
  );
}

export default Advantages;